@font-face {
  font-family: 'SFMonoMedium';
  src: url('../../../fonts/SFMonoMedium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'SFMonoRegular';
  src: url('../../../fonts/SFMonoRegular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Monaco';
  src: url('../../../fonts/Monaco.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.App {
  text-align: center;
  color: #333;
}

.navbar {
  font-family: 'SFMonoMedium', 'Arial', sans-serif;
  width: 100%;
  background-color: #ffffff;
  color: black;
  padding: 1vw 0;
  text-align: center;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2);
  z-index: 1;
  position: relative;
  min-height: 50px;
  font-size: max(.75vw, 10px);
  
  /* Animation properties */
  opacity: 0; /* Initially hidden */
  transform: translateY(-20px); /* Move up by 20px initially */
  animation: slideDownFadeIn 0.6s ease-in-out forwards; /* Duration and timing */
}

@keyframes slideDownFadeIn {
  to {
    opacity: 1;
    transform: translateY(0); /* Move back to the original position */
  }
}

@media (max-width: 414px) {
  .navbar {
    font-size: max(2vw, 10px); /* Adjust font size for smaller screens */
    padding: 2vw 0;
  }
}

.resume_container {
  height: max(75vh, 300px);
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  background-color: #ffffff;
}
.card {
  border-radius: 10px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.3);
  width: 50%;
  min-width: 360px;
  height: 60%;
  background-color: #fcfcfc;
  padding: 25px;
}
.drop_box {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 3px dotted #a3a3a3;
  border-radius: 10px;
}
.upload-section{
  cursor: pointer;
  width: 100%;
  height: 100%;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border-radius: 5px;
}
.upload-section:hover {
  transform: scale(1.05);
}
.upload-section img {
  max-width: 100px; /* Limit the image size */
  align-items: center;
  height: auto;
  display: block;
  margin: 12vh auto 0 auto;
}

.upload-label {
  font-family: 'SFMonoRegular', 'Arial', sans-serif;
  font-size: 15px;
  color: #807c7c;
  padding: 10px;
  border-radius: 5px;
  z-index: 1; 
  pointer-events: none; 
  display: inline-block;
}

.letter {
  display: inline-block;
  opacity: 0;
  transform: translateY(20px); /* Start letters from slightly below */
  animation: fadeInUp 0.5s forwards; /* Animation duration for each letter */
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px); /* Start slightly below */
  }
  100% {
    opacity: 1;
    transform: translateY(0); /* Move to original position */
  }
}


/* PDF Section */
.react-pdf__Page {
  margin-top: 10px;
}

.react-pdf__Page__canvas {
  margin: 0 1px;
}

/* For all pages */
.pdf-container {
  height: 100%;
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
}
.pdf-section {
  cursor: pointer;
  height: 100%;
  max-height: 500px;
  overflow: auto;
  box-shadow:
    /* Top shadow */
    0 -5px 5px -5px rgba(0, 0, 0, 0.5),
    /* Right shadow */
     5px 0 5px -5px rgba(0, 0, 0, 0.5),
    /* Left shadow */
    -5px 0 5px -5px rgba(0, 0, 0, 0.5);

}

.pdf-section::-webkit-scrollbar {
  display: none;
}

/* next step button */
.next-step-button {
  font-family: 'SFMonoRegular', 'Arial', sans-serif;
  cursor: pointer;
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: #000000;
  border: none;
  border-radius: 10px;
  opacity: 0; /* Initially hidden */
  transform: translateY(10px); /* Slightly moved down */
  transition: opacity 0.3s ease, transform 1s ease; /* Add transitions */
}

.next-step-button.show {
  opacity: 1; /* Becomes visible */
  transform: translateY(0); /* Moves to normal position */
}

.next-step-button:disabled {
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
}


/* General scrollbar styles */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track-piece {
  background-color: #FFF;
}

::-webkit-scrollbar-thumb {
  background-color: #CBCBCB;
  outline: 2px solid #FFF;
  outline-offset: -2px;
  border: 0.1px solid #B7B7B7;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #909090;
}
