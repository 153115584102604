@font-face {
    font-family: 'SFMonoMedium';
    src: url('../../../fonts/SFMonoMedium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'SFMonoRegular';
    src: url('../../../fonts/SFMonoRegular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Monaco';
    src: url('../../../fonts/Monaco.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  
  .App {
    text-align: center;
    color: #333;
  }
  
  .navbar {
    font-family: 'SFMonoMedium', 'Arial', sans-serif;
    width: 100%;
    background-color: #ffffff;
    color: black;
    padding: 1vw 0;
    text-align: center;
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2);
    z-index: 1;
    position: relative;
    min-height: 50px;
    font-size: max(.75vw, 10px);
    
    /* Animation properties */
    opacity: 0; /* Initially hidden */
    transform: translateY(-20px); /* Move up by 20px initially */
    animation: slideDownFadeIn 0.6s ease-in-out forwards; /* Duration and timing */
  }
  @keyframes slideDownFadeIn {
    to {
      opacity: 1;
      transform: translateY(0); /* Move back to the original position */
    }
  }
  
  @media (max-width: 414px) {
    .navbar {
      font-size: max(2vw, 10px); /* Adjust font size for smaller screens */
      padding: 2vw 0;
    }
  }
  
  .login_container {
    font-family: 'SFMonoRegular', 'Arial', sans-serif;
    width: 80%;
    max-width: 600px;
    margin: auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: clamp(15px, 2vh, 25px);
  }

  .login_button {
    font-family: 'SFMonoRegular', 'Arial', sans-serif;
    cursor: pointer;
    padding: 10px 20px;
    font-size: 16px;
    color: white;
    background-color: #000000;
    border: none;
    border-radius: 10px;
    transition: opacity 0.5s ease, transform 1s ease; /* Adds transition */
    opacity: 0;
    transform: translateY(10px); /* Initial position slightly lower */
  }
  
  .login_button.show {
    opacity: 1; /* Button becomes visible */
    transform: translateY(0); /* Moves to normal position */
  }
  
  .login_button:disabled {
    background-color: #cccccc;
    color: #666666;
  }