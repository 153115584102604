.editor-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    height: 100vh; /* Full height of the viewport */
    padding: 20px;
    background-color: #f4f4f4; /* Light grey background for better contrast */
}

.iframe-content {
    width: 90%; /* Responsive width */
    height: 80vh; /* Responsive height based on viewport height */
    border: 2px solid #ccc; /* Subtle border for the iframe */
    background-color: #fff; /* White background inside the iframe */
    margin-top: 10px; /* Space between button and iframe */
}

.download-button {
    font-size: 16px; /* Legible font size */
    padding: 10px 20px; /* Padding for comfort */
    color: #fff; /* White text */
    background-color: #007bff; /* Bootstrap primary color for familiarity */
    border: none; /* No border for simplicity */
    border-radius: 5px; /* Rounded corners for modern look */
    cursor: pointer; /* Pointer cursor on hover */
    outline: none; /* Remove outline to enhance focus */
    transition: background-color 0.3s; /* Smooth transition for hover effects */
}

.download-button:hover {
    background-color: #0056b3; /* Darker shade on hover for button */
}
