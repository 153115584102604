@font-face {
    font-family: 'SFMonoMedium';
    src: url('../../../fonts/SFMonoMedium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'SFMonoRegular';
    src: url('../../../fonts/SFMonoRegular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Monaco';
    src: url('../../../fonts/Monaco.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
.App {
    color: #333;
}
.navbar {
    font-family: 'SFMonoMedium', 'Arial', sans-serif;
    width: 100%;
    background-color: #ffffff;
    color: black;
    padding: 1vw 0;
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2);
    z-index: 1;
    position: relative;
    min-height: 50px;
    font-size: max(.75vw, 10px);

    display: flex;
    align-items: center;
    justify-content: center; /* Centers items horizontally */
    
    /* Animation properties */
    opacity: 0; /* Initially hidden */
    transform: translateY(-20px); /* Move up by 20px initially */
    animation: slideDownFadeIn 0.6s ease-in-out forwards; /* Duration and timing */
}
.navbar-left {
    flex: 3.5; /* Takes up 2/5 of the navbar width */
    display: flex;
    align-items: center;
    justify-content: flex-end; /* Aligns content to the left */
    padding-left: 20px; /* Optional padding for spacing */
}

.navbar-center {
    flex: 1; /* Takes up 1/5 of the navbar width */
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.navbar-right {
    flex: 3.5; /* Takes up 2/5 of the navbar width */
    display: flex;
    align-items: center;
    justify-content: flex-start; /* Aligns content to the right */
    padding-right: 20px; /* Optional padding for spacing */
}

@keyframes slideDownFadeIn {
    to {
      opacity: 1;
      transform: translateY(0); /* Move back to the original position */
    }
  }
  
  @media (max-width: 414px) {
    .navbar {
      font-size: max(2vw, 10px); /* Adjust font size for smaller screens */
      padding: 2vw 0;
    }
  }
  

.nav-button {
    padding: 0.5em;
    font-size: 1.2em; /* Adjust icon size */
    border: none;
    background: none; /* Remove background for icon-only look */
    color: black;
    cursor: pointer;
    transition: border-bottom 0.5s ease;
    
    border-bottom: 3px solid transparent;
}

.nav-button:hover {
    border-bottom: 3px solid black;
}

.nav-button i {
    margin: 0; /* Removes any spacing */
}



.title{
    font-family: 'SFMonoRegular', 'Arial', sans-serif;
    margin: auto;
    padding-top: 20px;
    font-size: clamp(15px, 2vh, 25px);
}
.search-bar {
    font-family: 'SFMonoRegular', 'Arial', sans-serif;
    width: 20vw;
    padding: 10px;
    border: none;
    border-bottom: 2px solid transparent;
    border-radius: 4px;
    transition: border-bottom 0.5s ease;
    margin-bottom: 20px;
    font-size: clamp(8px, 1.5vh, 15px);
}
.search-bar:focus {
    outline: none;
    border-bottom: 2px solid black; /* Adds a black underline when focused */
}
.search-bar::placeholder {
    text-align: center; /* Center-align placeholder text */
    color: #a3a3a3; /* Optional: Change placeholder text color */
  }
.content {
    max-width: 100%;
    overflow-x: auto;
    justify-content: center;
    padding: 0 10px;
}

/* Table styling */
.job-table {
    width: 100%; /* Adjusts to fill the available container width */
    max-width: 1200px; /* Set a max width to avoid stretching too wide */
    height: 15vh;
    margin-left: auto;
    table-layout: auto;
    border-collapse: collapse;
}

/* Header and cell styling */
.job-table-header th {
    font-family: 'Monaco', 'Arial', sans-serif;
    font-size: 15px;
    padding: 10px;
    text-align: center;
    border-bottom: 1px solid white;
    white-space: nowrap;
}
/* Add this hover effect */
.job-table-header th:hover {
    transition: .5s ease;
    border-bottom: 1px solid black;
    cursor: pointer;
}

.job-table td {
    font-family: 'Monaco', 'Arial', sans-serif;
    font-size: 15px;
    padding: 10px;
    text-align: left;
    white-space: nowrap;
}

/* Table header and column width adjustments */
.job-table-header th {
    cursor: pointer;
    padding: 10px;
    width: 10vw;
    white-space: nowrap;
}

/* Button styling */
.add-button {
    font-family: 'SFMonoRegular', 'Arial', sans-serif;
    font-size: 14px;
    font-weight: bold;
    color: #ffffff;
    background-color: #333;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    
    /* Initial hidden state */
    opacity: 0;
    transform: translateY(10px); /* Slightly lower */
    transition: opacity 0.5s ease, transform 0.5s ease; /* Smooth transition */
}

/* Show state with fade-in and slide-up */
.add-button.show {
    cursor: pointer;
    opacity: 1;
    transform: translateY(0); /* Moves to normal position */
}

/* Style for input fields with bottom border only */
.job-table input[type="text"],
.job-table input[type="date"],
.job-table select {
  font-family: 'SFMonoRegular', 'Arial', sans-serif;
  border: none;
  outline: none;
  padding: 5px;
  font-size: 15px;
  background-color: transparent;
  border-bottom: 2px solid transparent;
  transition: border-bottom 0.5s ease;
}

.job-table input[type="text"]:focus,
.job-table input[type="date"]:focus,
.job-table select:focus {
    border-bottom: 2px solid black;
}

/* Style for placeholder text */
.job-table input::placeholder,
.job-table input[type="date"]::placeholder,
.job-table input[type="text"]::placeholder,
.job-table .status-select option[value=""]{
    text-align: left;
    color: #a3a3a3;
}

.react-datepicker-popper {
    z-index: 0 !important;
}

/* Additional styling for the table */

.job-table th {
    font-family: 'SFMonoRegular', 'Arial', sans-serif;
    font-size: 15px;
    cursor: pointer;
    padding: 10px;
  }

.job-table td{
    font-family: 'Monaco', 'Arial', sans-serif;
    font-size: 15px;
    padding: 20px;
    text-align: left;
}


@keyframes fadeInBottomUp {
    from {
      opacity: 0;
      transform: translateY(-20px); /* Starts slightly below */
    }
    to {
      opacity: 1;
      transform: translateY(0); /* Ends in its original position */
    }
  }
  
  .fade-in-row {
    animation: fadeInBottomUp 0.6s ease forwards; /* Duration and easing */
    opacity: 0; /* Ensures rows are hidden initially */
  }
  