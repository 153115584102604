/* Font Definitions */
@font-face {
    font-family: 'SFMonoMedium';
    src: url('../../../fonts/SFMonoMedium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'SFMonoRegular';
    src: url('../../../fonts/SFMonoRegular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Monaco';
    src: url('../../../fonts/Monaco.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  
  /* General styling for the app */
  .App {
    text-align: center;
    color: #333;
    background-color: #f7f7f7;
  }
  
  /* Navbar styling */
  .navbar {
    font-family: 'SFMonoMedium', 'Arial', sans-serif;
    width: 100%;
    background-color: #ffffff;
    color: black;
    padding: 1vw 0;
    text-align: center;
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2);
    z-index: 1;
    position: relative;
    min-height: 50px;
    font-size: max(.75vw, 10px);
  
    /* Animation properties */
    opacity: 0; /* Initially hidden */
    transform: translateY(-20px); /* Move up by 20px initially */
    animation: slideDownFadeIn 0.6s ease-in-out forwards; /* Duration and timing */
  }
  
  /* Animation for Navbar */
  @keyframes slideDownFadeIn {
    to {
      opacity: 1;
      transform: translateY(0); /* Move back to the original position */
    }
  }
  
  @media (max-width: 414px) {
    .navbar {
      font-size: max(2vw, 10px); /* Adjust font size for smaller screens */
      padding: 2vw 0;
    }
  }
  
  /* Profile container */
  .profile-container {
    font-family: 'SFMonoRegular', 'Arial', sans-serif;
    width: 80%;
    max-width: 1200px;
    margin: auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: clamp(15px, 2vh, 25px);
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 40px;
  }
  
  /* Profile Header: Profile Picture and Name */
  .profile-header {
    text-align: center;
  }
  
  .profile-pic {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    border: 5px solid #ddd;
  }
  
  .profile-header h2 {
    margin-top: 15px;
    font-size: 24px;
    font-weight: 600;
    color: #333;
    font-family: 'SFMonoMedium', 'Arial', sans-serif;
  }
  
  /* Profile Body: Two columns layout */
  .profile-body {
    display: flex;
    gap: 20px;
    margin-top: 20px;
    width: 100%;
  }
  
  .left-column, .right-column {
    flex: 1;
  }
  
  /* Left Column - Skills, Education, Projects */
  .left-column h3, .right-column h3 {
    font-size: 20px;
    margin-bottom: 10px;
    color: #333;
    border-bottom: 2px solid #ddd;
    padding-bottom: 5px;
  }
  
  ul {
    list-style: none;
    padding: 0;
    margin: 10px 0;
  }
  
  ul li {
    margin-bottom: 8px;
    color: #555;
  }
  
  ul li strong {
    font-weight: bold;
    color: #333;
  }
  
  /* Right Column - Personal Information */
  .right-column ul li {
    color: #555;
    font-size: 18px;
  }
  
  /* Skills, Projects, and Education sections */
  .left-column ul li, .right-column ul li {
    font-size: 18px;
    color: #555;
  }
  
  /* Scrollbars for the profile */
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  
  ::-webkit-scrollbar-track-piece {
    background-color: #FFF;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #CBCBCB;
    outline: 2px solid #FFF;
    outline-offset: -2px;
    border: 0.1px solid #B7B7B7;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background-color: #909090;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .profile-body {
      flex-direction: column;
      align-items: center;
    }
    .left-column, .right-column {
      width: 100%;
      margin-bottom: 20px;
    }
  }
  
  /* Button Animations */
  .analyze-button {
    font-family: 'SFMonoRegular', 'Arial', sans-serif;
    cursor: pointer;
    padding: 10px 20px;
    font-size: 16px;
    color: white;
    background-color: #000000;
    border: none;
    border-radius: 10px;
    transition: opacity 0.5s ease, transform 1s ease; /* Adds transition */
    opacity: 0;
    transform: translateY(10px); /* Initial position slightly lower */
  }
  
  .analyze-button.show {
    opacity: 1; /* Button becomes visible */
    transform: translateY(0); /* Moves to its normal position */
  }
  
  /* Status Container */
  .status-container {
    font-family: 'SFMonoRegular', 'Arial', sans-serif;
    margin-top: 20px;
    color: #333;
  }
  
  /* Loading animation */
  .loading-container {
    display: block;
    margin-top: 50px;
    width: 50%;
    height: 50%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .loading-square {
    width: 20px;
    height: 20px;
    background-color: black;
    position: absolute;
    animation: moveSquare 8s infinite;
  }
  
  .loading-square:nth-child(1) {
    animation-delay: -4.5s;
  }
  
  .loading-square:nth-child(2) {
    animation-delay: -2.5s;
  }
  
  .loading-square:nth-child(3) {
    animation-delay: -0.5s;
  }
  
  .loading-square:nth-child(4) {
    animation-delay: 1.5s;
  }
  
  /* Keyframes for loading squares */
  @keyframes moveSquare {
    0% {
      transform: translate(0, 0);
    }
    10% {
      transform: translate(25px, 0);
    }
    20% {
      transform: translate(25px, 0);
    }
    30% {
      transform: translate(50px, 0);
    }
    40% {
      transform: translate(50px, 0);
    }
    50% {
      transform: translate(75px, 0);
    }
    60% {
      transform: translate(75px, 0);
    }
    65% {
      transform: translate(75px, -25px);
    }
    80% {
      transform: translate(0, -25px);
    }
    100% {
      transform: translate(0, 0);
    }
  }
  
  .loading-gif {
    display: block;
    margin-top: 50px;
    width: 50%;
    height: 50%;
    margin-left: auto;
    margin-right: auto;
  }
  