@font-face {
  font-family: 'SFMonoMedium';
  src: url('../../../fonts/SFMonoMedium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'SFMonoRegular';
  src: url('../../../fonts/SFMonoRegular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Monaco';
  src: url('../../../fonts/Monaco.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.App {
  text-align: center;
  color: #333;
}

.navbar {
  font-family: 'SFMonoMedium', 'Arial', sans-serif;
  width: 100%;
  background-color: #ffffff;
  color: black;
  padding: 1vw 0;
  text-align: center;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2);
  z-index: 1;
  position: relative;
  min-height: 50px;
  font-size: max(.75vw, 10px);
  
  /* Animation properties */
  opacity: 0; /* Initially hidden */
  transform: translateY(-20px); /* Move up by 20px initially */
  animation: slideDownFadeIn 0.6s ease-in-out forwards; /* Duration and timing */
}

@keyframes slideDownFadeIn {
  to {
    opacity: 1;
    transform: translateY(0); /* Move back to the original position */
  }
}

@media (max-width: 414px) {
  .navbar {
    font-size: max(2vw, 10px); /* Adjust font size for smaller screens */
    padding: 2vw 0;
  }
}

.job-description-container {
  font-family: 'SFMonoRegular', 'Arial', sans-serif;
  width: 80%;
  max-width: 600px;
  margin: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: clamp(15px, 2vh, 25px);
}

.job-description-form{
  width: 75%;
}

.textarea {
  width: 100%;
  height: 15px;
  padding: 10px;
  border: none;
  border-bottom: 2px solid transparent;
  border-radius: 4px;
  resize: none;
  overflow: hidden;
  transition: border-bottom 0.5s ease;
  margin-bottom: 20px;
  font-size: max(2vh, 8px);
  
}
.textarea::placeholder {
  text-align: center; /* Center-align placeholder text */
  color: #a3a3a3; /* Optional: Change placeholder text color */
}

.textarea:focus {
  outline: none;
  border-bottom: 2px solid black; /* Adds a black underline when focused */
}

.job-description-textarea::-webkit-scrollbar {
  width: 8px; /* Scrollbar width */
}

.analyze-button {
  font-family: 'SFMonoRegular', 'Arial', sans-serif;
  cursor: pointer;
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: #000000;
  border: none;
  border-radius: 10px;
  transition: opacity 0.5s ease, transform 1s ease; /* Adds transition */
  opacity: 0;
  transform: translateY(10px); /* Initial position slightly lower */
}

.analyze-button.show {
  opacity: 1; /* Button becomes visible */
  transform: translateY(0); /* Moves to its normal position */
}

.status-container {
  font-family:'SFMonoRegular','Arial', sans-serif;
  margin-top: 20px;
}

/* Loading animation */
.loading-container {
  display: block;
  margin-top: 50px;
  width: 50%;
  height: 50%;
  margin-left: auto;
  margin-right: auto;
}

.loading-square {
  width: 20px;
  height: 20px;
  background-color: black;
  position: absolute;
  animation: moveSquare 8s infinite;
}

.loading-square:nth-child(1){
  animation-delay: -4.5s;
}
.loading-square:nth-child(2) {
  animation-delay: -2.5s;
}
.loading-square:nth-child(3) {
  animation-delay: -0.5s;
}
.loading-square:nth-child(4) {
  animation-delay: 1.5s;
}

@keyframes moveSquare {
  0% {
    transform: translate(0, 0);
  }
  10% {
    transform: translate(25px, 0);
  }
  20% {
    transform: translate(25px, 0);
  }
  30% {
    transform: translate(50px, 0);
  }
  40% {
    transform: translate(50px, 0);
  }
  50% {
    transform: translate(75px, 0);
  }
  60% {
    transform: translate(75px, 0);
  }
  65% {
    transform: translate(75px, -25px);
  }
  80% {
    transform: translate(0, -25px);
  }
  100% {
    transform: translate(0, 0);
  }
}

.loading-gif {
  display: block;
  margin-top: 50px;
  width: 50%;
  height: 50%;
  margin-left: auto;
  margin-right: auto;
}

/* General scrollbar styles */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track-piece {
  background-color: #FFF;
}

::-webkit-scrollbar-thumb {
  background-color: #CBCBCB;
  outline: 2px solid #FFF;
  outline-offset: -2px;
  border: 0.1px solid #B7B7B7;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #909090;
}
